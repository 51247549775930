
	import UiIcon from "@/components/UI/Icon/UiIcon";

	export default {
		name: "Alert",
		components: {UiIcon},
		props: {
			type: {
				type: String,
				default: 'info',
				validator(value) {
					return ['info', 'warning'].includes(value)
				},
			}
		}
	}
