
	import {ValidationProvider} from 'vee-validate';
	import IconButton from "../buttons/IconButton.vue";
	// TODO: move icons to kit
	import UiIcon from "@/components/UI/Icon/UiIcon.vue";

	export default {
		name: "BaseInput",
		components: {ValidationProvider, IconButton, UiIcon},
		directives: {},
		props: {
			id: {type: String, required: true},
			name: {type: String, required: true},
			value: {type: [String, Number], default: ''},
			label: {type: String, default: ''},
			placeholder: {type: String, default: ''},
			hint: {type: String, default: ''},
			required: {type: Boolean},
			clearable: {type: Boolean},
			rows: {
				type: [Number, String],
				default: 4
			},
			mode: {
				validator(value) {
					// https://vee-validate.logaretm.com/v3/guide/interaction-and-ux.html#interaction-modes
					return ['aggressive', 'passive', 'eager', 'lazy'].includes(value)
				},
				default: 'passive',
			},
			rules: {type: Array, default: () => []} // ['required', 'email']
		},
		emits: ['update:modelValue'],
		data() {
			return {
				isPasswordVisible: false,
			}
		},
		computed: {
			classes() {
				return {
					'input--clear': this.clearable,
				};
			},
			validateRules() {
				const rules = [...this.rules];

				if (this.required && !rules.includes('required')) {
					rules.push('required');
				}

				if (this.type === 'email' && !rules.includes('email')) {
					rules.push('email');
				}

				return rules.join("|");
			}
		},

		methods: {
			changeValue(event) {
				this.$emit('input', event.target.value);
				if (this.mode === 'passive') {
					this.$refs.provider.reset();
				}
			},
			clearValue() {
				this.$emit('input', '')
				this.$refs.input.focus();
			},
		},
	}
