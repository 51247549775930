import { render, staticRenderFns } from "./Textarea.vue?vue&type=template&id=60aa90df&scoped=true&"
import script from "./Textarea.vue?vue&type=script&lang=js&"
export * from "./Textarea.vue?vue&type=script&lang=js&"
import style0 from "./Textarea.vue?vue&type=style&index=0&id=60aa90df&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60aa90df",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiIcon: require('/usr/src/app/components/UI/Icon/UiIcon.vue').default})
