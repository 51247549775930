
	import UiButton from "@/components/ui-kit/buttons/Button";
	import UiInput from "@/components/ui-kit/form/Input";
	import UiIcon from "@/components/UI/Icon/UiIcon";
	import UiTextarea from "@/components/ui-kit/form/Textarea";

	import {mapActions, mapState} from "vuex";
	import {ValidationObserver} from "vee-validate";
	import Alert from "@/components/ui-kit/alerts/Alert";

	import utmMixin from "@/mixins/utm";
	import gtmButtonClick from '@/mixins/gtm-button-click';

	export default {
		name: "ManualSearchBanner",
		mixins: [utmMixin, gtmButtonClick],
		components: {
			Alert,
			UiIcon,
			UiTextarea,
			UiInput,
			UiButton,
			ValidationObserver
		},
		data() {
			return {
				processing: false,
				isSent: false,
				values: {
					contacts: {
						first_name: '',
						email_or_phone: '',
					},
					request_text: ''
				}
			}
		},
		computed: {
			...mapState('auth', ['isAuth']),
		},
		methods: {
			...mapActions({
				sendRequestToSearch: "rubricator/sendRequestToSearch",
			}),

			open () {
				this.$modal.show('manual-search-request-modal');

				try {
					this.gtmButtonClick(
						'searchpsy',
						'individual',
						'button',
						'click',
						'clientPage'
					)
				} catch (e) {
					if (this.$sentry) {
						this.$sentry.captureException(e)
					}
				}
			},

			async sendRequest() {
				try {
					this.processing = true;

					await this.sendRequestToSearch({
						is_public: !this.isAuth,
						// data: this.values
						data: {
							contacts: this.values.contacts,
							request_text: this.values.request_text || null
						}
					});
					this.isSent = true;

					try {
						this.gtmButtonClick(
							'individual form',
							'individual',
							'form',
							'sendform',
							'clientPage'
						)
					} catch (e) {
						if (this.$sentry) {
							this.$sentry.captureException(e)
						}
					}

				} catch (e) {
					// if (e?.response) {
					// 	if (e.response.status === 400) {
					// 		if (e.response?.data?.error_code) {
					// 			switch (e.response.data.error_code) {
					// 				case 'acquiring.certificate.already_used': {
					// 					this.$refs.form.setErrors({certificate_code: ['Сертификат уже активирован']});
					// 					break;
					// 				}
					// 			}
					// 		}
					// 	}
					// } else {
					this.$toast('Что-то пошло не так, обратитесь в поддержку', {type: 'error'});
					// }
				} finally {
					this.processing = false;
				}
			}
		}
	}
