
	import {ValidationProvider} from 'vee-validate';
	import IconButton from "../buttons/IconButton.vue";
	// TODO: move icons to kit
	import UiIcon from "@/components/UI/Icon/UiIcon.vue";
	import {VueMaskDirective} from 'v-mask'

	export default {
		name: "BaseInput",
		components: {ValidationProvider, IconButton, UiIcon},
		directives: {},
		props: {
			id: {type: String, required: true},
			name: {type: String, required: true},
			type: {type: String, default: 'text'},
			value: {type: [String, Number], default: ''},
			label: {type: String, default: ''},
			placeholder: {type: String, default: ''},
			hint: {type: String, default: ''},
			required: {type: Boolean},
			readonly: {type: Boolean},
			clearable: {type: Boolean},
			mask: String,
			mode: {
				validator(value) {
					// https://vee-validate.logaretm.com/v3/guide/interaction-and-ux.html#interaction-modes
					return ['aggressive', 'passive', 'eager', 'lazy'].includes(value)
				},
				default: 'passive',
			},
			rules: {type: Array, default: () => []} // ['required', 'email']
		},
		emits: ['update:modelValue'],
		data() {
			return {
				isPasswordVisible: false,
				localType: this.type
			}
		},
		computed: {
			classes() {
				return {
					'input--clear': this.clearable,
				};
			},
			validateRules() {
				const rules = [...this.rules];

				if (this.required && !rules.includes('required')) {
					rules.push('required');
				}

				if (this.type === 'email' && !rules.includes('email')) {
					rules.push('email');
				}

				return rules.join("|");
			}
		},

		methods: {
			changeValue(event) {
				this.$emit('input', event.target.value);
				this.$emit('changed', event.target.value);
				if (this.mode === 'passive') {
					this.$refs.provider.reset();
				}
			},
			clearValue() {
				this.$emit('input', '');
				this.$emit('changed', '');
				this.$refs.input.focus();
			},
			togglePasswordVisibility() {
				this.isPasswordVisible = !this.isPasswordVisible
				this.localType = this.isPasswordVisible ? 'text' : this.type
			},
		},
	}
